var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _c("transition", { attrs: { name: "slide" } }, [
        _c(
          "main",
          [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass: "col-md-12",
                  staticStyle: { "padding-bottom": "20px" },
                },
                [
                  _c("b-button", {
                    staticClass: "btn btn-primary btn-md float-right",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.FormMSG(
                          201,
                          "Download Albert Carbon footprint data"
                        )
                      ),
                    },
                    on: { click: _vm.downloadExport },
                  }),
                ],
                1
              ),
            ]),
            _vm.$screen.width < 992
              ? _c("MobileViewsLocationList", {
                  attrs: {
                    locations: _vm.locationList,
                    showDeleteButton: false,
                  },
                  on: {
                    "show-picture": _vm.showPicture,
                    "card-clicked": _vm.handleEditLocation,
                  },
                })
              : _vm._e(),
            _vm.$screen.width >= 992
              ? _c(
                  "b-card",
                  { attrs: { "no-body": "" } },
                  [
                    _c("b-card-body", [
                      _c("h4", [
                        _vm._v(_vm._s(this.FormMSG(2, "Non-fiming Spaces"))),
                      ]),
                      _vm.$screen.width >= 992
                        ? _c(
                            "div",
                            { staticClass: "location-list-container" },
                            [
                              _c("b-table", {
                                staticStyle: { "text-align": "left" },
                                attrs: {
                                  hover: _vm.hover,
                                  responsive: "sm",
                                  items: _vm.nonFilmingSpaceList,
                                  fields: _vm.projectLocationsListFields,
                                  "sticky-header": "400px",
                                  bordered: "",
                                  small: "",
                                  "head-variant": _vm.hv,
                                },
                                on: { "row-clicked": _vm.handleEditLocation },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "cell(pict)",
                                      fn: function (data) {
                                        return [
                                          data.item.images.length
                                            ? _c(
                                                "b-button",
                                                {
                                                  attrs: {
                                                    variant: "success",
                                                    size: "sm",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.showPicture(
                                                        data.item.images
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    _vm.getLucideIcon(
                                                      _vm.ICONS.PICTURE.name
                                                    ),
                                                    {
                                                      tag: "component",
                                                      attrs: {
                                                        color:
                                                          _vm.ICONS.PICTURE
                                                            .color,
                                                        size: 20,
                                                      },
                                                    }
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1080800229
                                ),
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            _vm.$screen.width >= 992
              ? _c(
                  "b-card",
                  { attrs: { "no-body": "" } },
                  [
                    _c("b-card-body", [
                      _c("h4", [
                        _vm._v(_vm._s(this.FormMSG(1, "Fiming Spaces"))),
                      ]),
                      _vm.$screen.width >= 992
                        ? _c(
                            "div",
                            { staticClass: "location-list-container" },
                            [
                              _c("b-table", {
                                staticStyle: { "text-align": "left" },
                                attrs: {
                                  hover: _vm.hover,
                                  responsive: "sm",
                                  items: _vm.filmingSpaceList,
                                  fields: _vm.projectLocationsListFields,
                                  "sticky-header": "400px",
                                  bordered: "",
                                  small: "",
                                  "head-variant": _vm.hv,
                                },
                                on: { "row-clicked": _vm.handleEditLocation },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "cell(pict)",
                                      fn: function (data) {
                                        return [
                                          data.item.images.length
                                            ? _c(
                                                "b-button",
                                                {
                                                  attrs: {
                                                    variant: "success",
                                                    size: "sm",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.showPicture(
                                                        data.item.images
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    _vm.getLucideIcon(
                                                      _vm.ICONS.PICTURE.name
                                                    ),
                                                    {
                                                      tag: "component",
                                                      attrs: {
                                                        color:
                                                          _vm.ICONS.PICTURE
                                                            .color,
                                                        size: 20,
                                                      },
                                                    }
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1080800229
                                ),
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            _vm.$screen.width >= 992
              ? _c(
                  "b-card",
                  { attrs: { "no-body": "" } },
                  [
                    _c("b-card-body", [
                      _c("h4", [
                        _vm._v(_vm._s(this.FormMSG(3, "Accommodations"))),
                      ]),
                      _vm.$screen.width >= 992
                        ? _c(
                            "div",
                            { staticClass: "location-list-container" },
                            [
                              _c("b-table", {
                                staticStyle: { "text-align": "left" },
                                attrs: {
                                  hover: _vm.hover,
                                  responsive: "sm",
                                  items: _vm.accomodations,
                                  fields: _vm.accomodationsFields,
                                  "sticky-header": "400px",
                                  bordered: "",
                                  small: "",
                                  "head-variant": _vm.hv,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }