<template>
	<errorContainer :error="erreur" :warning="warning">
		<transition name="slide">
			<main>
				<div class="row">
					<!-- <b-col md="12">
						<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, , { 'is-pwa': $isPwa() }]">
							{{ this.getTitle() }}
						</h1>
					</b-col> -->

					<div class="col-md-12" style="padding-bottom: 20px">
						<b-button
							class="btn btn-primary btn-md float-right"
							v-html="FormMSG(201, 'Download Albert Carbon footprint data')"
							@click="downloadExport"
						/>
					</div>
				</div>
				<MobileViewsLocationList
					v-if="$screen.width < 992"
					:locations="locationList"
					:showDeleteButton="false"
					@show-picture="showPicture"
					@card-clicked="handleEditLocation"
				/>
				<!-- NON FILMING SPACES -->
				<b-card no-body v-if="$screen.width >= 992">
					<b-card-body>
						<h4>{{ this.FormMSG(2, 'Non-fiming Spaces') }}</h4>

						<div v-if="$screen.width >= 992" class="location-list-container">
							<b-table
								:hover="hover"
								responsive="sm"
								:items="nonFilmingSpaceList"
								style="text-align: left"
								:fields="projectLocationsListFields"
								@row-clicked="handleEditLocation"
								sticky-header="400px"
								bordered
								small
								:head-variant="hv"
							>
								<template v-slot:cell(pict)="data">
									<b-button v-if="data.item.images.length" variant="success" size="sm" @click="showPicture(data.item.images)">
										<component :is="getLucideIcon(ICONS.PICTURE.name)" :color="ICONS.PICTURE.color" :size="20" />
									</b-button>
								</template>
							</b-table>
						</div>
					</b-card-body>
				</b-card>
				<!-- FILMING SPACES -->
				<b-card no-body v-if="$screen.width >= 992">
					<b-card-body>
						<h4>{{ this.FormMSG(1, 'Fiming Spaces') }}</h4>
						<div v-if="$screen.width >= 992" class="location-list-container">
							<b-table
								:hover="hover"
								responsive="sm"
								:items="filmingSpaceList"
								style="text-align: left"
								:fields="projectLocationsListFields"
								@row-clicked="handleEditLocation"
								sticky-header="400px"
								bordered
								small
								:head-variant="hv"
							>
								<template v-slot:cell(pict)="data">
									<b-button variant="success" v-if="data.item.images.length" size="sm" @click="showPicture(data.item.images)">
										<component :is="getLucideIcon(ICONS.PICTURE.name)" :color="ICONS.PICTURE.color" :size="20" />
									</b-button>
								</template>
							</b-table>
						</div>
					</b-card-body>
				</b-card>
				<!-- ACCOMODATIONS -->
				<b-card no-body v-if="$screen.width >= 992">
					<b-card-body>
						<h4>{{ this.FormMSG(3, 'Accommodations') }}</h4>

						<div v-if="$screen.width >= 992" class="location-list-container">
							<b-table
								:hover="hover"
								responsive="sm"
								:items="accomodations"
								style="text-align: left"
								:fields="accomodationsFields"
								sticky-header="400px"
								bordered
								small
								:head-variant="hv"
							>
							</b-table>
						</div>
					</b-card-body>
				</b-card>
			</main>
			<!-- </div> -->
		</transition>
	</errorContainer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { store } from '@/store';
import { isNil } from '@/shared/utils';
import { getFileExtension } from '@/shared/helpers';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import MobileViewsLocationList from '@/components/MobileViews/Location/Main';
import { rendKwh, rendWaterQty } from '~helpers';
import gql from 'graphql-tag';

const query_accomodations = gql`
	query {
		GetAllAccomodationDeliveries {
			numberOfNights
			numberOfPersons
			user {
				name
				firstName
			}
			acc {
				name
				type
				dtype
				supplier {
					address {
						state
						country
					}
				}
			}
		}
	}
`;

export default {
	name: 'AlbertCarbonFootprint',
	components: { MobileViewsLocationList },
	mixins: [languageMessages, globalMixin],
	props: {
		hover: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			hv: 'dark',
			erreur: {},
			warning: '',
			filmingSpaces: [],
			nonFilmingSpaces: [],
			accomodations: [],
			locationPageType: 'add-edit',

			isFormOpen: false
		};
	},

	computed: {
		...mapGetters({
			currLocation: 'locations/currLocation',
			projectLocationsList: 'locations/projectLocationsList'
		}),

		locationList() {
			this.projectLocationsList.forEach(this.fillEnergyTotals);
			return this.projectLocationsList;
		},

		filmingSpaceList() {
			this.projectLocationsList.forEach(this.fillEnergyTotals);
			this.filmingSpaces = [];
			for (var i = 0; i < this.projectLocationsList.length; i++) {
				if (this.projectLocationsList[i].type == 36 || this.projectLocationsList[i].type == 37 || this.projectLocationsList[i].type == 299) {
					this.filmingSpaces.push(this.projectLocationsList[i]);
				}
			}
			//console.log("filming spaces:",this.filmingSpaces);
			return this.filmingSpaces;
		},

		nonFilmingSpaceList() {
			this.projectLocationsList.forEach(this.fillEnergyTotals);
			this.nonFilmingSpaces = [];
			for (var i = 0; i < this.projectLocationsList.length; i++) {
				if (this.projectLocationsList[i].type == 1 || this.projectLocationsList[i].type == 2 || this.projectLocationsList[i].type == 3) {
					this.nonFilmingSpaces.push(this.projectLocationsList[i]);
				}
			}
			//console.log("filming spaces:",this.filmingSpaces);
			return this.nonFilmingSpaces;
		},

		projectLocationsListFields() {
			return [
				/*
        {
          key: "id",
          label: this.FormMSG(3, "Id"),
          formatter: (v) => `#${v}`,
          sortable: true,
        },*/
				{
					key: 'setName',
					label: this.FormMSG(4, 'Name'),
					sortable: true
				},
				{
					key: 'setAddress.state',
					label: this.FormMSG(5, 'State'),
					formatter: (v) => v,
					sortable: true
				},
				{
					key: 'setAddress.country',
					label: this.FormMSG(6, 'Country'),
					formatter: (v) => v,
					sortable: true
				},
				{
					key: 'totalElec',
					label: this.FormMSG(7, 'Electricity'),
					formatter: (v) => rendKwh(v),
					class: 'text-right',
					sortable: true
				},
				{
					key: 'totalGas',
					label: this.FormMSG(8, 'Gas'),
					formatter: (v) => rendKwh(v),
					class: 'text-right',
					sortable: true
				},
				{
					key: 'totalWater',
					label: this.FormMSG(9, 'Water'),
					formatter: (v) => rendWaterQty(v),
					class: 'text-right',
					sortable: true
				},
				{
					key: 'pict',
					label: 'Picture',
					label: this.FormMSG(10, 'Picture'),
					formatter: (v) => v,
					sortable: false,
					class: 'text-center'
				}
			];
		},
		accomodationsFields() {
			return [
				{
					key: 'acc.name',
					label: this.FormMSG(23, 'Hotel'),
					sortable: true
				},
				{
					key: 'acc.dtype',
					label: this.FormMSG(27, 'Type'),
					sortable: true
				},
				{
					key: 'user.name',
					label: this.FormMSG(24, 'Name'),
					sortable: true
				},
				{
					key: 'user.firstName',
					label: this.FormMSG(26, 'First Name'),
					sortable: true
				},
				{
					key: 'acc.dtype',
					label: this.FormMSG(15, 'Type'),
					sortable: true
				},
				{
					key: 'acc.supplier.address.state',
					label: this.FormMSG(5, 'State'),
					sortable: true
				},
				{
					key: 'acc.supplier.address.country',
					label: this.FormMSG(6, 'Country'),
					sortable: true
				},
				{
					key: 'numberOfPersons',
					label: this.FormMSG(17, 'Number of Persons'),
					class: 'text-right',
					sortable: true
				},
				{
					key: 'numberOfNights',
					label: this.FormMSG(18, 'Number of Nights'),
					class: 'text-right',
					sortable: true
				}
			];
		}
	},

	created() {
		this.getProjectLocationList();
		this.loadAccomodationsData();
	},

	methods: {
		getTitle() {
			return this.FormMSG(111, 'Carbon Footprint');
		},
		fillEnergyTotals(loc) {
			// compute total for elec, gaz and water
			//console.log("loc:",loc);
			loc.totalElec = loc.elecMeterEnd - loc.elecMeterStart;
			loc.totalGas = loc.gasMeterEnd - loc.gasMeterStart;
			loc.totalWater = loc.waterMeterEnd - loc.waterMeterStart;
		},

		...mapActions({
			getProjectLocationList: 'locations/getProjectLocationList',
			setCurrentLocation: 'locations/setCurrentLocation',
			destroyCurrentLocation: 'locations/destroyCurrentLocation'
		}),

		showPicture(images) {
			if (isNil(images)) return;

			const list = images.map((img) => ({
				xid: img,
				src: `${process.env.VUE_APP_GQL}/images/${img}`,
				thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + img,
				ext: getFileExtension(img)
			}));

			this.$previewImages({
				images: list,
				focusIndex: 0,
				options: {
					hideLikeDislike: true,
					hideCommentButton: true,
					hideDisLikeButton: true,
					presentationMode: 'doc'
				}
			});
		},

		loadAccomodationsData() {
			//fetchPolicy: 'no-cache'

			this.$apollo
				.query({
					query: query_accomodations,
					variables: {},
					// options: { fetchPolicy: 'network-only' },
					// options: { fetchPolicy: 'no-cache' },
					// force update from server
					fetchPolicy: 'network-only'
				})
				.then((result) => {
					this.accomodations = result.data.GetAllAccomodationDeliveries;
				})
				.catch((error) => {
					console.log(error);
					this.erreur = error;
				});
		},

		/**
		 * @param {Object} location
		 */
		async handleEditLocation(location) {
			await this.setCurrentLocation(location);
			this.goToForm();
		},

		goToForm() {
			if (isNil(this.currLocation)) return;
			const { id } = this.currLocation;
			this.$router.push({ path: `/locations/${id}` });
		},

		downloadExport() {
			//console.log("indownload");

			this.isLoading = true;
			this.warning = '';

			var fromDate = this.from + 'T00:00:00Z';
			var toDate = this.to + 'T00:00:00Z';

			var config = {
				headers: {
					Authorization: 'Bearer ' + store.state.token
				}
			};
			var servicePath = 'DownloadAlbertReport';
			//console.log("servicePath:",servicePath);
			var fullPath = process.env.VUE_APP_GQL + '/' + servicePath + '/?Email=0';
			console.log('fullPath:', fullPath);

			var axiosTmp = require('axios').create();
			axiosTmp
				.get(fullPath, config)
				.then((response) => {
					if (response.status == 200) {
						var urlStr = process.env.VUE_APP_GQL + '/images/' + response.data;
						console.log('urlStr:', urlStr);
						this.$nextTick(function () {
							console.log('in nexttick url:', urlStr);
							//window.location.href = urlStr;
							window.open(urlStr); // in a new window
						});
					}
				})
				.catch((error) => {
					console.log({
						error
					});
					if (error.message.includes('Network')) {
						this.warning = 'Network error.';
					} else if (error.response) {
						if (error.response.status == 401) {
							this.warning = 'Not connected.';
						} else if (error.response.status == 400) {
							this.warning = 'Invalid format.';
						} else if (error.response.status == 404) {
							this.warning = this.FormMSG(22, 'No Data in the selected period.');
						}
					}
				})
				.finally(() => (this.isLoading = false));
		}
	}
};
</script>
